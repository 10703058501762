import React, { useState, useEffect } from "react"
import Layout from "@/components/layout"
import Axios from "axios"
import MdWrapper from "@/components/MarkdownWrap"

const Index = () => {
    const [markdown, setMarkdown] = useState(null)

    const getMd = () => {
        Axios.get(`${process.env.resourceUrl}docs/vulnerability_reports.md`).then(function (data) {
            setMarkdown(data.data)
        })
    }

    useEffect(() => {
        getMd()
    }, [])

    return (
        <Layout menuBlack={true}>
            <div style={{height:"36px"}}></div>
            {markdown != null ? (
                <MdWrapper md={markdown} stl={{ marginTop: 0 }} />
            ) : (
                ""
            )}
        </Layout>
    )
}

export default Index